import React from "react";
import { navigate } from "gatsby";

import { AppBar, Toolbar, IconButton } from "@mui/material";

import DialpadIcon from '@mui/icons-material/Dialpad';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ListIcon from '@mui/icons-material/List';

import Root, { classes } from "../../components/styles/menu";

import _ from "lodash";

import Menu from "../lmvh-list2/menu";

const TopBar = ({ children, keypadSwitch, onKeypad, keypadShown,
    showBackbutton = true, title = "Root", transparentAppbar = false, ...props }) => {

    const hideBackButton = showBackbutton ? {} : { style: { display: 'none' } };

    const barStyle = { style: { boxShadow: 'none' } };
    if (transparentAppbar) barStyle.style.backgroundColor = 'transparent';
    return <Root>
        <AppBar className={classes.appBar} {...barStyle} >
            <Toolbar className={classes.toolbar}>

                <IconButton
                    {...hideBackButton}
                    edge="start"
                    color="inherit"
                    aria-label="backbutton"
                    onClick={() => { navigate(-1); }}
                    size="large">
                    <ArrowBackIcon />
                </IconButton>


                <div className={classes.rightItems}>

                    {keypadSwitch &&
                        <IconButton
                            edge="end"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="keypad"
                            onClick={onKeypad}
                            size="large">
                            {keypadShown ? <ListIcon /> : <DialpadIcon />}
                        </IconButton>
                    }

                    <Menu {...props} />

                </div>
            </Toolbar>
        </AppBar>
        {/*</Grow>*/}
        <div className={classes.toolbarMargin} />
    </Root>;
}

export default TopBar;
